export const graphicOrganizerEmpty_ELA_english_1_ELA_E1_AOR_1_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Analyzing Perspectives: The Festival Tree Conflict</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        th, td {
            border: 1px solid #ddd;
            padding: 12px;
            text-align: left;
            vertical-align: top;
        }
        th {
            background-color: #233142;
            color: white;
            font-weight: bold;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 80px;
            resize: vertical;
            box-sizing: border-box;
        }
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .pre-filled {
            background-color: #f9f9f9;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Analyzing Perspectives: The Festival Tree Conflict</h1>
        
        <div class="instructions">
            <p>Use text evidence to analyze each character's perspective on the community conflict about "The Festival Tree." Explain how each viewpoint and literary elements deepen the story's meaning.</p>
        </div>
        
        <div class="organizer-sheet">
            <table>
                <thead>
                    <tr>
                        <th>Character</th>
                        <th>Feelings & Motivations (Context)</th>
                        <th>Key Literary Evidence (Quote & Literary Element)</th>
                        <th>How does this perspective deepen meaning?</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Mr. Henry Clarke</td>
                        <td class="pre-filled">Sad, worried about losing memories, values history and tradition.</td>
                        <td class="pre-filled">Quote: "That tree tells our story." (Symbolism: Tree symbolizes heritage and community history; Tone: Sad, nostalgic)</td>
                        <td><textarea class="text-area" placeholder="Explain how Mr. Clarke's perspective deepens the meaning of the story..."></textarea></td>
                    </tr>
                    <tr>
                        <td>Maya</td>
                        <td class="pre-filled">Hopeful, excited about change, values creativity and new experiences.</td>
                        <td class="pre-filled">Quote: "Change can be good." (Tone: Optimistic, excited; Characterization: Imaginative, open-minded)</td>
                        <td><textarea class="text-area" placeholder="Explain how Maya's perspective deepens the meaning of the story..."></textarea></td>
                    </tr>
                    <tr>
                        <td>Mr. Delgado</td>
                        <td class="pre-filled">Anxious, uncertain about his business, values economic stability.</td>
                        <td class="pre-filled">Quote: "If they keep arguing, no one will come shop." (Tone: Nervous, concerned; Characterization: Practical, worried business owner)</td>
                        <td><textarea class="text-area" placeholder="Explain how Mr. Delgado's perspective deepens the meaning of the story..."></textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_ELA_english_1_ELA_E1_AOR_1_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Analyzing Perspectives: The Festival Tree Conflict</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-bottom: 20px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .organizer-sheet {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        th, td {
            border: 1px solid #ddd;
            padding: 12px;
            text-align: left;
            vertical-align: top;
        }
        th {
            background-color: #233142;
            color: white;
            font-weight: bold;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: 'Comic Sans MS', cursive, sans-serif;
            font-size: 16px;
            min-height: 80px;
            resize: vertical;
            box-sizing: border-box;
        }
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .pre-filled {
            background-color: #f9f9f9;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Analyzing Perspectives: The Festival Tree Conflict</h1>
        
        <div class="instructions">
            <p>Use text evidence to analyze each character's perspective on the community conflict about "The Festival Tree." Explain how each viewpoint and literary elements deepen the story's meaning.</p>
        </div>
        
        <div class="organizer-sheet">
            <table>
                <thead>
                    <tr>
                        <th>Character</th>
                        <th>Feelings & Motivations (Context)</th>
                        <th>Key Literary Evidence (Quote & Literary Element)</th>
                        <th>How does this perspective deepen meaning?</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Mr. Henry Clarke</td>
                        <td class="pre-filled">Sad, worried about losing memories, values history and tradition.</td>
                        <td class="pre-filled">Quote: "That tree tells our story." (Symbolism: Tree symbolizes heritage and community history; Tone: Sad, nostalgic)</td>
                        <td><textarea class="text-area" readonly>Mr. Clarke's perspective shows how the tree represents the town's history and memories. His sad, nostalgic tone helps us understand that the tree is more than just a plant - it's a connection to the past. This makes the conflict deeper because it's not just about cutting down a tree, but about losing something meaningful from the community's history. His view helps us see why some people resist change even when it might be necessary.</textarea></td>
                    </tr>
                    <tr>
                        <td>Maya</td>
                        <td class="pre-filled">Hopeful, excited about change, values creativity and new experiences.</td>
                        <td class="pre-filled">Quote: "Change can be good." (Tone: Optimistic, excited; Characterization: Imaginative, open-minded)</td>
                        <td><textarea class="text-area" readonly>Maya's perspective shows the positive side of change and looking forward instead of backward. Her optimistic tone and artistic imagination (painting bright pictures) reveal how young people often see opportunities where others see loss. This deepens the story by creating tension between preserving the past and creating the future. Her view balances Mr. Clarke's and shows that both perspectives have value, making the town's decision more complex.</textarea></td>
                    </tr>
                    <tr>
                        <td>Mr. Delgado</td>
                        <td class="pre-filled">Anxious, uncertain about his business, values economic stability.</td>
                        <td class="pre-filled">Quote: "If they keep arguing, no one will come shop." (Tone: Nervous, concerned; Characterization: Practical, worried business owner)</td>
                        <td><textarea class="text-area" readonly>Mr. Delgado's perspective adds a practical dimension to the conflict. Unlike the others who focus on emotional connections or future possibilities, he worries about how the arguing affects business right now. His anxious tone shows that community conflicts have real economic consequences. This deepens the story by introducing financial concerns, making the issue more realistic and complicated. His view reminds us that community decisions affect people's livelihoods, not just their feelings.</textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_ELA_english_1_ELA_E1_AOR_1_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Analyzing Perspectives: The Festival Tree Conflict</title>
    <style>
        body {
            font-family: 'Comic Sans MS', Arial, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 850px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 5px;
            font-size: 24px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            font-size: 16px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        th, td {
            border: 1px solid #233142;
            padding: 12px;
            text-align: left;
            vertical-align: top;
        }
        th {
            background-color: #233142;
            color: white;
            font-weight: bold;
            font-size: 14px;
        }
        .writing-area {
            width: 100%;
            min-height: 100px;
            border: 1px solid #aaa;
            border-radius: 5px;
            background-color: #ffffff;
            background-image: linear-gradient(#e5e5e5 1px, transparent 1px);
            background-size: 100% 25px;
            position: relative;
            box-sizing: border-box;
        }
        .pre-filled {
            background-color: #f9f9f9;
            font-size: 14px;
        }
        
        /* Print styles */
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            table, tr, td, th {
                page-break-inside: avoid;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Analyzing Perspectives: The Festival Tree Conflict</h1>
        
        <div class="instructions">
            <p>Use text evidence to analyze each character's perspective on the community conflict about "The Festival Tree." Explain how each viewpoint and literary elements deepen the story's meaning.</p>
        </div>
        
        <table>
            <thead>
                <tr>
                    <th>Character</th>
                    <th>Feelings & Motivations (Context)</th>
                    <th>Key Literary Evidence (Quote & Literary Element)</th>
                    <th>How does this perspective deepen meaning?</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Mr. Henry Clarke</td>
                    <td class="pre-filled">Sad, worried about losing memories, values history and tradition.</td>
                    <td class="pre-filled">Quote: "That tree tells our story." (Symbolism: Tree symbolizes heritage and community history; Tone: Sad, nostalgic)</td>
                    <td>
                        <div class="writing-area"></div>
                    </td>
                </tr>
                <tr>
                    <td>Maya</td>
                    <td class="pre-filled">Hopeful, excited about change, values creativity and new experiences.</td>
                    <td class="pre-filled">Quote: "Change can be good." (Tone: Optimistic, excited; Characterization: Imaginative, open-minded)</td>
                    <td>
                        <div class="writing-area"></div>
                    </td>
                </tr>
                <tr>
                    <td>Mr. Delgado</td>
                    <td class="pre-filled">Anxious, uncertain about his business, values economic stability.</td>
                    <td class="pre-filled">Quote: "If they keep arguing, no one will come shop." (Tone: Nervous, concerned; Characterization: Practical, worried business owner)</td>
                    <td>
                        <div class="writing-area"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</body>
</html>`;

export const studentResource_ELA_english_1_ELA_E1_AOR_1_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Through Another's Eyes: Festival Fiasco</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        h2 {
            color: #233142;
            margin-top: 25px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .perspective {
            background-color: #f9f9f9;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border-left: 4px solid #233142;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .instructions, .vocabulary, .fun-fact, .perspective {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Through Another's Eyes: Festival Fiasco</h1>
        <div class="subtitle">Understanding Different Perspectives</div>
        
        <div class="vocabulary">
            <div class="vocabulary-title">Key Vocabulary:</div>
            <strong>perspective</strong> - a particular way of viewing things<br>
            <strong>memory</strong> - something remembered from the past<br>
            <strong>community</strong> - a group of people living in the same place
        </div>
        
        <h2>The Festival Tree</h2>
        <p>Everyone in Greenville loved the old oak tree in the town square. Folks came every year for the festival held around the tree. But this year, the mayor said the tree was old and unsafe. It had to come down. Soon, the town began to argue.</p>
        
        <div class="perspective">
            <h2>Perspective #1: Mr. Henry Clarke (Older Resident)</h2>
            <p>Mr. Clarke frowned as he looked at the big oak. He thought about picnics he had there when he was a child. "That tree tells our story," he said quietly. "We cannot cut down our memories." Mr. Clarke felt sad and worried about losing the past.</p>
        </div>
        
        <div class="perspective">
            <h2>Perspective #2: Maya (Teenage Student)</h2>
            <p>Maya painted bright pictures in her notebook. She imagined a new space with tables, music, and murals. "Change can be good," Maya smiled. "We can make new memories." Maya felt hopeful and excited about the future.</p>
        </div>
        
        <div class="perspective">
            <h2>Perspective #3: Mr. Delgado (Local Business Owner)</h2>
            <p>Mr. Delgado stood outside his store, looking at the arguing crowds. He felt nervous about business. "If they keep arguing, no one will come shop," he worried. For him, the tree was important for customers and sales. Mr. Delgado felt anxious and unsure.</p>
        </div>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Think About It!</div>
            <p>When we see things from different perspectives, we understand the whole story better. Each person has their own reasons for feeling the way they do.</p>
        </div>
        
        <h2>Closing Reflection</h2>
        <p>The tree meant different things to different people. For Mr. Clarke, it was history. For Maya, it was a chance for something new. For Mr. Delgado, it was about business and money. Each person felt strongly because of their own story. Understanding their feelings helps us see the whole picture and think more deeply about our community.</p>
    </div>
</body>
</html>`;

export const teacherResource_ELA_english_1_ELA_E1_AOR_1_1 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Teacher Resource: Through Another's Eyes: Festival Fiasco</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        h2 {
            color: #233142;
            margin-top: 25px;
        }
        h3 {
            color: #233142;
        }
        h4 {
            margin-top: 0;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .teacher-header {
            background-color: #233142;
            color: white;
            padding: 10px 15px;
            border-radius: 5px;
            margin-bottom: 20px;
        }
        .objectives {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #3fa672;
        }
        .discussion {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .guidance {
            background-color: #fff8dc;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #ddd;
        }
        .summary {
            background-color: #f0f0f0;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .implementation {
            background-color: #ffe6e6;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        ul, ol {
            margin-top: 5px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .objectives, .discussion, .guidance, .summary, .implementation {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="teacher-header" style="color: white">
            <h1 style="color: white">TEACHER RESOURCE ONLY</h1>
            <div class="subtitle" style="color: white">Through Another's Eyes: Festival Fiasco - Instructional Guide</div>
        </div>
        
        <div class="summary">
            <h3>Resource Summary</h3>
            <p>This student resource presents a narrative about a town's conflict over removing an old oak tree, told through three different perspectives: an older resident (Mr. Clarke), a teenage student (Maya), and a local business owner (Mr. Delgado). The story illustrates how different backgrounds, values, and priorities shape individuals' perspectives on the same situation.</p>
        </div>
        
        <div class="objectives">
            <h3>Learning Objectives</h3>
            <ul>
                <li>Students will analyze how perspective, context, and literary elements (tone, characterization, symbolism) shape meaning.</li>
                <li>Students will identify and compare different character viewpoints on the same event.</li>
                <li>Students will recognize how a character's background influences their perspective.</li>
                <li>Students will apply critical thinking to understand multiple sides of a community issue.</li>
            </ul>
        </div>
        
        <div class="discussion">
            <h3>Discussion Questions</h3>
            <ol>
                <li>How did Mr. Clarke's memories help us understand his feelings?</li>
                <li>Why was Maya excited about the change? How did her imagination help you see her point of view?</li>
                <li>Why was Mr. Delgado worried? How does the way he thinks about the tree differ from others?</li>
                <li>How do different perspectives help readers understand the depth of the conflict?</li>
                <li>Which perspective do you most relate to and why?</li>
                <li>How does the author use specific details to develop each character's unique viewpoint?</li>
                <li>What symbols does each character associate with the tree? What do these reveal about their values?</li>
            </ol>
        </div>
        
        <div class="guidance">
            <h3>Teaching Guidance</h3>
            <p>Use this simple, accessible narrative to help students recognize and identify how different characters' views, feelings, and contexts shape the meaning of the story. After reading each short perspective aloud together, have students discuss in pairs or small groups how different viewpoints change the way we feel and think about the tree.</p>
            
            <p>Guide students to use literary vocabulary (tone, symbolism, characterization) during discussion to explicitly connect this accessible resource to the more complex standard. Emphasize how:</p>
            <ul>
                <li><strong>Tone:</strong> Each character's section has a distinct emotional tone (nostalgic, optimistic, anxious)</li>
                <li><strong>Characterization:</strong> Details about each character reveal their priorities and values</li>
                <li><strong>Symbolism:</strong> The tree represents different things to each character (history, opportunity, economic stability)</li>
            </ul>
        </div>
        
        <div class="implementation">
            <h3>Implementation Strategies</h3>
            <h4>Before Reading:</h4>
            <ul>
                <li>Activate prior knowledge by asking students about a time when they disagreed with someone about a change in their school or community.</li>
                <li>Pre-teach vocabulary: perspective, community, symbolism, tone.</li>
                <li>Set purpose: "We're going to see how three different people feel about the same problem."</li>
            </ul>
            
            <h4>During Reading:</h4>
            <ul>
                <li>Use a think-aloud strategy to model perspective-taking with the first character.</li>
                <li>Create a simple T-chart for each character: "What they think" | "Why they think it"</li>
                <li>Pause after each perspective to discuss the character's feelings and motivations.</li>
            </ul>
            
            <h4>After Reading:</h4>
            <ul>
                <li>Have students create a Venn diagram comparing two perspectives.</li>
                <li>Role-play a town meeting where students represent different characters.</li>
                <li>Write a short paragraph from a fourth perspective (e.g., the mayor, a child, etc.)</li>
            </ul>
            
            <h4>Assessment Opportunities:</h4>
            <ul>
                <li><strong>Formative:</strong> Monitor student discussion contributions for understanding of perspective.</li>
                <li><strong>Written:</strong> Have students complete a perspective analysis worksheet identifying key details that reveal each character's viewpoint.</li>
                <li><strong>Creative:</strong> Students create a visual representation showing the three perspectives side by side.</li>
                <li><strong>Extended:</strong> Students write a resolution to the conflict that acknowledges all perspectives.</li>
            </ul>
        </div>
        
        <div class="guidance">
            <h3>Differentiation Strategies</h3>
            <h4>For Struggling Readers:</h4>
            <ul>
                <li>Create a simple emotion chart with faces to help identify each character's feelings.</li>
                <li>Pre-highlight key sentences that reveal perspective in each section.</li>
                <li>Provide sentence frames: "Mr. Clarke feels _____ because _____."</li>
            </ul>
            
            <h4>For Advanced Learners:</h4>
            <ul>
                <li>Connect to more complex texts with multiple perspectives on community issues.</li>
                <li>Analyze how word choice and sentence structure contribute to tone in each section.</li>
                <li>Research real-world community conflicts and analyze various stakeholder perspectives.</li>
            </ul>
        </div>
        
        <div class="guidance">
            <h3>South Carolina ELA Standards Alignment</h3>
            <p>This resource addresses the following English 1 standards:</p>
            <ul>
                <li><strong>Standard 5:</strong> Determine meaning and develop logical interpretations by making predictions, inferring, drawing conclusions, analyzing, synthesizing, providing evidence, and investigating multiple interpretations.</li>
                <li><strong>Standard 6:</strong> Summarize key details and ideas to support analysis of thematic development.</li>
                <li><strong>Standard 8:</strong> Analyze characters, settings, events, and ideas as they develop and interact within a particular context.</li>
                <li><strong>Standard 11:</strong> Analyze and critique how the author uses structures in print and multimedia texts to shape meaning and impact the reader.</li>
            </ul>
        </div>
    </div>
</body>
</html>`;

