export const graphicOrganizerEmpty_science_biology_B_LS2_3 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Cycling of Matter and Energy: Aerobic vs. Anaerobic</title>
    <style>
        body {
            font-family: 'Arial', sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 900px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 20px;
            font-size: 24px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .comparison-container {
            display: flex;
            margin-top: 20px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .column {
            flex: 1;
            padding: 15px;
            box-sizing: border-box;
        }
        .column:first-child {
            border-right: 1px solid #ddd;
        }
        .column-header {
            font-weight: bold;
            color: #233142;
            text-align: center;
            font-size: 18px;
            padding-bottom: 10px;
            border-bottom: 2px solid #233142;
            margin-bottom: 15px;
        }
        .section {
            margin-bottom: 20px;
        }
        .section-title {
            font-weight: bold;
            margin-bottom: 5px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin-bottom: 10px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: Arial, sans-serif;
            font-size: 16px;
        }
        .input-field:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .radio-group {
            display: flex;
            justify-content: space-around;
            margin-top: 10px;
        }
        .radio-option {
            display: flex;
            align-items: center;
        }
        .radio-option label {
            margin-left: 4px;
        }
        .summary-section {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .summary-title {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: Arial, sans-serif;
            font-size: 16px;
            min-height: 80px;
            resize: vertical;
        }
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .input-label {
            display: block;
            margin-bottom: 5px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                size: landscape;
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Cycling of Matter and Energy: Aerobic vs. Anaerobic</h1>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <p>Complete this graphic organizer to compare aerobic and anaerobic respiration. Fill in the blanks based on your understanding of how matter cycles and energy flows in these different conditions.</p>
        </div>
        
        <div class="comparison-container">
            <!-- Left Column - Aerobic -->
            <div class="column">
                <div class="column-header">Aerobic Respiration (with Oxygen)</div>
                
                <div class="section">
                    <div class="section-title">List the TWO main inputs:</div>
                    <label class="input-label">Input 1:</label>
                    <input type="text" class="input-field" name="aerobic_input1">
                    
                    <label class="input-label">Input 2:</label>
                    <input type="text" class="input-field" name="aerobic_input2">
                </div>
                
                <div class="section">
                    <div class="section-title">Where in the cell does it happen?</div>
                    <label class="input-label">Place:</label>
                    <input type="text" class="input-field" name="aerobic_place">
                </div>
                
                <div class="section">
                    <div class="section-title">List the THREE main outputs:</div>
                    <label class="input-label">Output 1 (Energy):</label>
                    <input type="text" class="input-field" name="aerobic_output1">
                    
                    <label class="input-label">Output 2:</label>
                    <input type="text" class="input-field" name="aerobic_output2">
                    
                    <label class="input-label">Output 3:</label>
                    <input type="text" class="input-field" name="aerobic_output3">
                </div>
                
                <div class="section">
                    <div class="section-title">Circle the correct answer:</div>
                    <div class="radio-group">
                        <div class="radio-option">
                            <input type="radio" id="aerobic_little" name="aerobic_energy" value="little">
                            <label for="aerobic_little">A Little ATP</label>
                        </div>
                        <div class="radio-option">
                            <input type="radio" id="aerobic_lot" name="aerobic_energy" value="lot">
                            <label for="aerobic_lot">A Lot of ATP</label>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Right Column - Anaerobic -->
            <div class="column">
                <div class="column-header">Anaerobic Respiration (no Oxygen)</div>
                
                <div class="section">
                    <div class="section-title">List the main input:</div>
                    <label class="input-label">Input:</label>
                    <input type="text" class="input-field" name="anaerobic_input">
                    
                    <div class="section-title">Is oxygen used? Circle your answer.</div>
                    <div class="radio-group">
                        <div class="radio-option">
                            <input type="radio" id="oxygen_yes" name="oxygen_used" value="yes">
                            <label for="oxygen_yes">Yes</label>
                        </div>
                        <div class="radio-option">
                            <input type="radio" id="oxygen_no" name="oxygen_used" value="no">
                            <label for="oxygen_no">No</label>
                        </div>
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">What kind of organisms do this?</div>
                    <label class="input-label">Organism 1:</label>
                    <input type="text" class="input-field" name="anaerobic_organism1">
                    
                    <label class="input-label">Organism 2:</label>
                    <input type="text" class="input-field" name="anaerobic_organism2">
                </div>
                
                <div class="section">
                    <div class="section-title">List TWO main outputs:</div>
                    <label class="input-label">Output 1 (Energy):</label>
                    <input type="text" class="input-field" name="anaerobic_output1">
                    
                    <label class="input-label">Output 2:</label>
                    <input type="text" class="input-field" name="anaerobic_output2">
                </div>
                
                <div class="section">
                    <div class="section-title">Circle the correct answer:</div>
                    <div class="radio-group">
                        <div class="radio-option">
                            <input type="radio" id="anaerobic_little" name="anaerobic_energy" value="little">
                            <label for="anaerobic_little">A Little ATP</label>
                        </div>
                        <div class="radio-option">
                            <input type="radio" id="anaerobic_lot" name="anaerobic_energy" value="lot">
                            <label for="anaerobic_lot">A Lot of ATP</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="summary-section">
            <div class="summary-title">In your own words, briefly explain ONE way aerobic and anaerobic respiration help recycle matter in ecosystems.</div>
            <textarea class="text-area" name="summary_explanation" rows="3"></textarea>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerFilled_science_biology_B_LS2_3 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Cycling of Matter and Energy: Aerobic vs. Anaerobic</title>
    <style>
        body {
            font-family: 'Arial', sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 900px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 20px;
            font-size: 24px;
        }
        .instructions {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 10px;
            margin: 15px 0;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .comparison-container {
            display: flex;
            margin-top: 20px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .column {
            flex: 1;
            padding: 15px;
            box-sizing: border-box;
        }
        .column:first-child {
            border-right: 1px solid #ddd;
        }
        .column-header {
            font-weight: bold;
            color: #233142;
            text-align: center;
            font-size: 18px;
            padding-bottom: 10px;
            border-bottom: 2px solid #233142;
            margin-bottom: 15px;
        }
        .section {
            margin-bottom: 20px;
        }
        .section-title {
            font-weight: bold;
            margin-bottom: 5px;
        }
        .input-field {
            width: 100%;
            padding: 8px;
            margin-bottom: 10px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: Arial, sans-serif;
            font-size: 16px;
        }
        .input-field:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .radio-group {
            display: flex;
            justify-content: space-around;
            margin-top: 10px;
        }
        .radio-option {
            display: flex;
            align-items: center;
        }
        .radio-option label {
            margin-left: 4px;
        }
        .summary-section {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #ddd;
            border-radius: 10px;
        }
        .summary-title {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .text-area {
            width: 100%;
            padding: 8px;
            border: 1px solid #aaa;
            border-radius: 5px;
            font-family: Arial, sans-serif;
            font-size: 16px;
            min-height: 80px;
            resize: vertical;
        }
        .text-area:focus {
            outline: none;
            border-color: #233142;
            box-shadow: 0 0 3px rgba(35, 49, 66, 0.5);
        }
        .input-label {
            display: block;
            margin-bottom: 5px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            .instructions {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                size: landscape;
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Cycling of Matter and Energy: Aerobic vs. Anaerobic</h1>
        
        <div class="instructions">
            <h3>Directions:</h3>
            <p>Complete this graphic organizer to compare aerobic and anaerobic respiration. Fill in the blanks based on your understanding of how matter cycles and energy flows in these different conditions.</p>
        </div>
        
        <div class="comparison-container">
            <!-- Left Column - Aerobic -->
            <div class="column">
                <div class="column-header">Aerobic Respiration (with Oxygen)</div>
                
                <div class="section">
                    <div class="section-title">List the TWO main inputs:</div>
                    <label class="input-label">Input 1:</label>
                    <input type="text" class="input-field" name="aerobic_input1" value="Glucose (sugar)">
                    
                    <label class="input-label">Input 2:</label>
                    <input type="text" class="input-field" name="aerobic_input2" value="Oxygen">
                </div>
                
                <div class="section">
                    <div class="section-title">Where in the cell does it happen?</div>
                    <label class="input-label">Place:</label>
                    <input type="text" class="input-field" name="aerobic_place" value="Mitochondria">
                </div>
                
                <div class="section">
                    <div class="section-title">List the THREE main outputs:</div>
                    <label class="input-label">Output 1 (Energy):</label>
                    <input type="text" class="input-field" name="aerobic_output1" value="ATP (energy)">
                    
                    <label class="input-label">Output 2:</label>
                    <input type="text" class="input-field" name="aerobic_output2" value="Carbon dioxide">
                    
                    <label class="input-label">Output 3:</label>
                    <input type="text" class="input-field" name="aerobic_output3" value="Water">
                </div>
                
                <div class="section">
                    <div class="section-title">Circle the correct answer:</div>
                    <div class="radio-group">
                        <div class="radio-option">
                            <input type="radio" id="aerobic_little" name="aerobic_energy" value="little">
                            <label for="aerobic_little">A Little ATP</label>
                        </div>
                        <div class="radio-option">
                            <input type="radio" id="aerobic_lot" name="aerobic_energy" value="lot" checked>
                            <label for="aerobic_lot">A Lot of ATP</label>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Right Column - Anaerobic -->
            <div class="column">
                <div class="column-header">Anaerobic Respiration (no Oxygen)</div>
                
                <div class="section">
                    <div class="section-title">List the main input:</div>
                    <label class="input-label">Input:</label>
                    <input type="text" class="input-field" name="anaerobic_input" value="Glucose (sugar)">
                    
                    <div class="section-title">Is oxygen used? Circle your answer.</div>
                    <div class="radio-group">
                        <div class="radio-option">
                            <input type="radio" id="oxygen_yes" name="oxygen_used" value="yes">
                            <label for="oxygen_yes">Yes</label>
                        </div>
                        <div class="radio-option">
                            <input type="radio" id="oxygen_no" name="oxygen_used" value="no" checked>
                            <label for="oxygen_no">No</label>
                        </div>
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">What kind of organisms do this?</div>
                    <label class="input-label">Organism 1:</label>
                    <input type="text" class="input-field" name="anaerobic_organism1" value="Yeast (Fermi)">
                    
                    <label class="input-label">Organism 2:</label>
                    <input type="text" class="input-field" name="anaerobic_organism2" value="Bacteria (Ana)">
                </div>
                
                <div class="section">
                    <div class="section-title">List TWO main outputs:</div>
                    <label class="input-label">Output 1 (Energy):</label>
                    <input type="text" class="input-field" name="anaerobic_output1" value="ATP (small amount)">
                    
                    <label class="input-label">Output 2:</label>
                    <input type="text" class="input-field" name="anaerobic_output2" value="Carbon dioxide or ethanol">
                </div>
                
                <div class="section">
                    <div class="section-title">Circle the correct answer:</div>
                    <div class="radio-group">
                        <div class="radio-option">
                            <input type="radio" id="anaerobic_little" name="anaerobic_energy" value="little" checked>
                            <label for="anaerobic_little">A Little ATP</label>
                        </div>
                        <div class="radio-option">
                            <input type="radio" id="anaerobic_lot" name="anaerobic_energy" value="lot">
                            <label for="anaerobic_lot">A Lot of ATP</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="summary-section">
            <div class="summary-title">In your own words, briefly explain ONE way aerobic and anaerobic respiration help recycle matter in ecosystems.</div>
            <textarea class="text-area" name="summary_explanation" rows="3">Both processes release carbon dioxide back into the atmosphere, which plants can use for photosynthesis. Anaerobic respiration is especially important in marshes where it recycles carbon that would otherwise stay trapped. Without these processes, carbon wouldn't cycle through ecosystems properly.</textarea>
        </div>
    </div>
</body>
</html>`;

export const graphicOrganizerPrintable_science_biology_B_LS2_3 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Cycling of Matter and Energy: Aerobic vs. Anaerobic</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .container {
            max-width: 900px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            margin-bottom: 20px;
            font-size: 24px;
        }
        .comparison-container {
            display: flex;
            margin-top: 20px;
            border: 1px solid #000;
            border-radius: 10px;
        }
        .column {
            flex: 1;
            padding: 15px;
            box-sizing: border-box;
        }
        .column:first-child {
            border-right: 1px solid #000;
        }
        .column-header {
            font-weight: bold;
            color: #233142;
            text-align: center;
            font-size: 18px;
            padding-bottom: 10px;
            border-bottom: 2px solid #233142;
            margin-bottom: 15px;
        }
        .section {
            margin-bottom: 20px;
        }
        .section-title {
            font-weight: bold;
            margin-bottom: 5px;
        }
        .writing-line {
            width: 100%;
            height: 30px;
            border-bottom: 1px solid #000;
            margin-bottom: 10px;
            position: relative;
        }
        .writing-line-label {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
        }
        .circle-options {
            display: flex;
            justify-content: space-around;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .circle-option {
            display: flex;
            align-items: center;
        }
        .circle {
            width: 20px;
            height: 20px;
            border: 1px solid #000;
            border-radius: 50%;
            margin-right: 10px;
            display: inline-block;
        }
        .summary-section {
            margin-top: 20px;
            padding: 15px;
            border: 1px solid #000;
            border-radius: 10px;
        }
        .summary-title {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .writing-area {
            width: 100%;
            height: 30px;
            border-bottom: 1px solid #000;
            margin-bottom: 10px;
        }
        
        /* Print styles */
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 15px;
                max-width: 100%;
            }
            @page {
                size: landscape;
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Cycling of Matter and Energy: Aerobic vs. Anaerobic</h1>
        
        <div class="comparison-container">
            <!-- Left Column - Aerobic -->
            <div class="column">
                <div class="column-header">Aerobic Respiration (with Oxygen)</div>
                
                <div class="section">
                    <div class="section-title">List the TWO main inputs:</div>
                    <div class="writing-line">
                        <span class="writing-line-label">Input 1: </span>
                    </div>
                    
                    <div class="writing-line">
                        <span class="writing-line-label">Input 2: </span>
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">Where in the cell does it happen?</div>
                    <div class="writing-line">
                        <span class="writing-line-label">Place: </span>
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">List the THREE main outputs:</div>
                    <div class="writing-line">
                        <span class="writing-line-label">Output 1 (Energy): </span>
                    </div>
                    
                    <div class="writing-line">
                        <span class="writing-line-label">Output 2: </span>
                    </div>
                    
                    <div class="writing-line">
                        <span class="writing-line-label">Output 3: </span>
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">Circle the correct answer:</div>
                    <div class="circle-options">
                        <div class="circle-option">
                            <span class="circle"></span>
                            <span>A Little ATP</span>
                        </div>
                        <div class="circle-option">
                            <span class="circle"></span>
                            <span>A Lot of ATP</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Right Column - Anaerobic -->
            <div class="column">
                <div class="column-header">Anaerobic Respiration (no Oxygen)</div>
                
                <div class="section">
                    <div class="section-title">List the main input:</div>
                    <div class="writing-line">
                        <span class="writing-line-label">Input: </span>
                    </div>
                    
                    <div class="section-title">Is oxygen used? Circle your answer.</div>
                    <div class="circle-options">
                        <div class="circle-option">
                            <span class="circle"></span>
                            <span>Yes</span>
                        </div>
                        <div class="circle-option">
                            <span class="circle"></span>
                            <span>No</span>
                        </div>
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">What kind of organisms do this?</div>
                    <div class="writing-line">
                        <span class="writing-line-label">Organism 1: </span>
                    </div>
                    
                    <div class="writing-line">
                        <span class="writing-line-label">Organism 2: </span>
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">List TWO main outputs:</div>
                    <div class="writing-line">
                        <span class="writing-line-label">Output 1 (Energy): </span>
                    </div>
                    
                    <div class="writing-line">
                        <span class="writing-line-label">Output 2: </span>
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-title">Circle the correct answer:</div>
                    <div class="circle-options">
                        <div class="circle-option">
                            <span class="circle"></span>
                            <span>A Little ATP</span>
                        </div>
                        <div class="circle-option">
                            <span class="circle"></span>
                            <span>A Lot of ATP</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="summary-section">
            <div class="summary-title">In your own words, briefly explain ONE way aerobic and anaerobic respiration help recycle matter in ecosystems.</div>
            <div class="writing-area"></div>
            <div class="writing-area"></div>
            <div class="writing-area"></div>
        </div>
    </div>
</body>
</html>`;

export const studentResource_science_biology_B_LS2_3 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Journey into the Microverse: Cellular Energy Adventures</title>
    <style>
        body {
            font-family: 'Comic Sans MS', cursive, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .vocabulary {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            border: 1px dashed #3fa672;
        }
        .vocabulary-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .fun-fact {
            background-color: #fff8dc;
            padding: 15px;
            margin: 15px 0;
            border-radius: 8px;
            border: 1px solid #ddd;
        }
        .fun-fact-title {
            font-weight: bold;
            color: #233142;
            margin-bottom: 5px;
        }
        .tasks {
            background-color: #f0f0f0;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
        }
        .task-title {
            font-weight: bold;
            margin-bottom: 5px;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            .vocabulary, .fun-fact, .tasks {
                border: 1px solid #ccc;
                box-shadow: none;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Journey into the Microverse</h1>
        <div class="subtitle">Life at the Edge: Adventures in Cellular Energy</div>
        
        <div class="vocabulary">
            <div class="vocabulary-title">Key Vocabulary:</div>
            <strong>Aerobic Respiration</strong> - Using oxygen to break down food for energy<br>
            <strong>Anaerobic Respiration</strong> - Making energy without oxygen<br>
            <strong>ATP</strong> - The energy currency cells use<br>
            <strong>Fermentation</strong> - A process that makes energy without oxygen<br>
            <strong>Carbon Cycle</strong> - How carbon moves through living things and the environment<br>
            <strong>Glucose</strong> - A sugar molecule used for energy
        </div>
        
        <p>"Are you ready?" asked Dr. Mira with a smile. Alex nodded nervously, stepping onto the shrinking platform. In a flash, Alex became as small as a bacteria cell and began an incredible microscopic adventure.</p>
        
        <p>Alex first entered a bright green leaf. The place was buzzing with energy! Alex met two friendly mitochondria named Mito and Oxy. "Welcome to our aerobic world," said Mito proudly. As Alex watched, sugar molecules (glucose) entered, oxygen was used, and energy (ATP) was created. Water and carbon dioxide formed, gently floating away. "We breathe oxygen and change sugar into energy," explained Oxy. "This energy helps plants and animals grow, move, and live."</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Fun Fact!</div>
            <p>Mitochondria are often called the "powerhouses of the cell" because they produce most of the cell's energy through aerobic respiration!</p>
        </div>
        
        <p>Just as Alex felt comfortable, a sudden shift transported him to a very different place—a slimy marsh deep underwater. It was dark, quiet, and had no oxygen. Alex met a funny yeast named Fermi and a tough bacteria named Ana. "We live without oxygen!" Ana boasted. "Watch how we make energy, Alex." Fermi took glucose, breaking it down into carbon dioxide and ethanol. Ana turned its sugar into strange-smelling compounds. But Alex noticed something: both produced much less energy (ATP) than Mito and Oxy had.</p>
        
        <p>"How do you survive with less energy?" Alex asked curiously. Fermi laughed, "We keep it simple. It's enough for our tiny lives, and we recycle carbon for the marsh. Without us, the carbon would stay stuck."</p>
        
        <div class="fun-fact">
            <div class="fun-fact-title">Fun Fact!</div>
            <p>The bubbles in bread happen because yeast performs fermentation, creating carbon dioxide gas that makes the dough rise!</p>
        </div>
        
        <p>Alex realized then that life had found clever ways to cycle matter and energy everywhere—whether oxygen-rich or oxygen-poor. He returned to normal size, excited to share how different organisms survive and cycle matter using aerobic and anaerobic processes.</p>
        
        <div class="tasks">
            <h3>Student Tasks:</h3>
            
            <div class="task-title">Task 1: "Explain the Difference"</div>
            <p>Using evidence from Alex's microscopic adventures, clearly explain why aerobic respiration creates significantly more ATP energy per glucose molecule compared to anaerobic fermentation. Include oxygen's role and the differences in outputs.</p>
            
            <div class="task-title">Task 2: "Matter Cycle Diagram"</div>
            <p>Draw a visual diagram (flowchart) showing how carbon travels through the atmosphere, aerobic respiration, anaerobic fermentation, and photosynthesis. Use arrows and labels clearly.</p>
            
            <div class="task-title">Task 3: Scenario Analysis – "The Yeast Experiment"</div>
            <p>Imagine a yeast fermenting sugar in bread dough. What happens if you remove sugar? What if you add oxygen? Explain why clearly.</p>
        </div>
    </div>
</body>
</html>`;

export const teacherResource_science_biology_B_LS2_3 = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Teacher Resource: Journey into the Microverse</title>
    <style>
        body {
            font-family: Arial, Helvetica, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 20px;
            display: flex;
            justify-content: center;
        }
        .container {
            max-width: 800px;
            width: 100%;
            background-color: white;
            padding: 25px;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }
        h1 {
            color: #233142;
            text-align: center;
            border-bottom: 2px solid #233142;
            padding-bottom: 10px;
        }
        .subtitle {
            color: #666;
            text-align: center;
            font-style: italic;
            margin-top: -10px;
            margin-bottom: 20px;
        }
        p {
            line-height: 1.6;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .teacher-header {
            background-color: #233142;
            color: white;
            padding: 15px;
            border-radius: 8px;
            margin-bottom: 20px;
            text-align: center;
        }
        .objectives {
            background-color: #e8f7ee;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px dashed #3fa672;
        }
        .discussion {
            background-color: #d9eeff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .extension {
            background-color: #fff8dc;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
            border: 1px solid #ddd;
        }
        .implementation {
            background-color: #f0e6ff;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .assessment {
            background-color: #ffe6e6;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        .section-title {
            color: #233142;
            margin-top: 0;
            border-bottom: 1px solid #ddd;
            padding-bottom: 5px;
        }
        ul, ol {
            line-height: 1.6;
        }
        .standards {
            background-color: #f0f0f0;
            padding: 15px;
            border-radius: 8px;
            margin: 15px 0;
        }
        @media print {
            body {
                background-color: white;
                padding: 0;
                margin: 0;
            }
            .container {
                box-shadow: none;
                padding: 0;
                max-width: 100%;
            }
            @page {
                margin: 1cm;
            }
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="teacher-header">
            <h1 style="color: white;">TEACHER RESOURCE ONLY</h1>
            <div class="subtitle" style="color: white;">Journey into the Microverse: Cellular Energy Adventures</div>
        </div>
        
        <div class="resource-summary">
            <h2 class="section-title">Resource Summary</h2>
            <p>This student resource presents a narrative adventure where a character named Alex shrinks to microscopic size to explore cellular respiration processes. The story contrasts aerobic respiration (with mitochondria characters) and anaerobic fermentation (with yeast and bacteria characters), while highlighting the carbon cycle and energy production differences between these processes.</p>
        </div>
        
        <div class="objectives">
            <h2 class="section-title">Learning Objectives</h2>
            <ul>
                <li>Students will explain and distinguish between aerobic and anaerobic respiration processes using textual evidence.</li>
                <li>Students will identify and describe how matter (carbon, oxygen) cycles through different environmental conditions.</li>
                <li>Students will reinforce and apply key vocabulary related to cellular respiration.</li>
                <li>Students will analyze how energy yield differs between aerobic and anaerobic processes.</li>
                <li>Students will create visual representations of carbon cycling through biological systems.</li>
            </ul>
        </div>
        
        <div class="standards">
            <h2 class="section-title">SC Science Standards Alignment</h2>
            <ul>
                <li><strong>H.B.2</strong>: The student will demonstrate an understanding of the flow of energy within and between living systems.</li>
                <li><strong>H.B.2A.1</strong>: Construct explanations of how energy flows within and between living systems.</li>
                <li><strong>H.B.2A.2</strong>: Analyze and interpret data to explain the concept of entropy as it relates to the availability of energy.</li>
                <li><strong>H.B.2B.1</strong>: Analyze the processes of photosynthesis and cellular respiration to explain how energy is transformed and stored in living systems.</li>
            </ul>
        </div>
        
        <div class="implementation">
            <h2 class="section-title">Implementation Strategies</h2>
            <h3>Before Reading</h3>
            <ul>
                <li>Activate prior knowledge by asking students what they know about how cells obtain energy.</li>
                <li>Review the key vocabulary terms with students, ensuring they understand the basic definitions.</li>
                <li>Consider having students make predictions about how organisms might survive in environments with and without oxygen.</li>
            </ul>
            
            <h3>During Reading</h3>
            <ul>
                <li><strong>Reading Options:</strong>
                    <ul>
                        <li>Read aloud as a class, pausing at key points to check for understanding</li>
                        <li>Have students read independently or in pairs</li>
                        <li>Use a jigsaw approach where student groups focus on different sections</li>
                    </ul>
                </li>
                <li><strong>Annotation Strategy:</strong> Have students underline or highlight evidence that shows differences between aerobic and anaerobic processes.</li>
                <li><strong>Guided Questions:</strong> Pause after each section to ask guiding questions about what's happening at the cellular level.</li>
            </ul>
            
            <h3>After Reading</h3>
            <ul>
                <li>Have students complete the three tasks provided in the resource.</li>
                <li>Consider implementing the extension activities listed below.</li>
                <li>Facilitate a whole-class discussion using the discussion questions provided.</li>
            </ul>
        </div>
        
        <div class="discussion">
            <h2 class="section-title">Discussion Questions</h2>
            <ol>
                <li><strong>Process Comparison:</strong> Why do aerobic organisms have higher ATP yields than anaerobic organisms? What evolutionary advantages and disadvantages might this create?</li>
                <li><strong>Ecological Impact:</strong> How do anaerobic organisms affect ecosystems like wetlands? What would happen if these organisms disappeared?</li>
                <li><strong>Environmental Connections:</strong> Why is carbon cycling important in different ecosystems? How do human activities impact these cycles?</li>
                <li><strong>Text Analysis:</strong> How does the personification of cellular components (Mito, Oxy, Fermi, Ana) help explain complex biological processes?</li>
                <li><strong>Application:</strong> Where do we see examples of aerobic and anaerobic processes in our everyday lives?</li>
                <li><strong>Deeper Thinking:</strong> Why might some organisms use fermentation even when oxygen is available?</li>
            </ol>
        </div>
        
        <div class="assessment">
            <h2 class="section-title">Assessment Strategies</h2>
            <h3>Formative Assessment</h3>
            <ul>
                <li><strong>Task 1 (Explain the Difference):</strong> Look for student explanations that correctly identify:
                    <ul>
                        <li>Oxygen's role as the final electron acceptor in aerobic respiration</li>
                        <li>Complete breakdown of glucose in aerobic respiration vs. partial breakdown in fermentation</li>
                        <li>Specific mention of ATP yield differences (approximately 36-38 ATP for aerobic vs. 2 ATP for anaerobic)</li>
                    </ul>
                </li>
                <li><strong>Task 2 (Matter Cycle Diagram):</strong> Evaluate diagrams based on:
                    <ul>
                        <li>Inclusion of all key processes (photosynthesis, aerobic respiration, anaerobic processes)</li>
                        <li>Correct direction of carbon flow between processes</li>
                        <li>Appropriate labels for inputs and outputs (CO₂, O₂, glucose, etc.)</li>
                        <li>Visual clarity and organization</li>
                    </ul>
                </li>
                <li><strong>Task 3 (Yeast Experiment):</strong> Look for explanations that demonstrate:
                    <ul>
                        <li>Understanding that removing sugar removes the energy source (no fermentation occurs)</li>
                        <li>Recognition that adding oxygen might shift yeast from fermentation to aerobic respiration (if genetically capable)</li>
                        <li>Ability to apply knowledge to a practical scenario</li>
                    </ul>
                </li>
            </ul>
            
            <h3>Summative Assessment Options</h3>
            <ul>
                <li><strong>Concept Map:</strong> Have students create a comprehensive concept map connecting all key terms and processes.</li>
                <li><strong>Lab Report:</strong> Design a simple fermentation experiment with yeast and have students predict, observe, and explain results.</li>
                <li><strong>Multimedia Presentation:</strong> Students create a presentation explaining cellular respiration to younger students.</li>
                <li><strong>Written Assessment:</strong> Include questions that require students to compare and contrast aerobic and anaerobic processes, explain energy yield differences, and apply understanding to novel scenarios.</li>
            </ul>
        </div>
        
        <div class="extension">
            <h2 class="section-title">Extension Activities</h2>
            <ul>
                <li><strong>Yeast Fermentation Lab:</strong> Set up experiments with yeast in different conditions (varying sugar, temperature, oxygen) to observe fermentation rates.</li>
                <li><strong>Cellular Respiration Models:</strong> Have students create physical or digital models showing the steps of aerobic and anaerobic respiration.</li>
                <li><strong>Career Connections:</strong> Research careers that utilize knowledge of fermentation (brewers, bakers, biofuel engineers, etc.).</li>
                <li><strong>Environmental Impact Study:</strong> Investigate how climate change affects carbon cycling and cellular respiration in various ecosystems.</li>
                <li><strong>Creative Writing:</strong> Students write their own "microscopic adventure" focusing on a different cellular process.</li>
                <li><strong>Comparative Analysis:</strong> Research and compare energy efficiency across different organisms (plants, mammals, bacteria, etc.).</li>
            </ul>
        </div>
        
        <div class="differentiation">
            <h2 class="section-title">Differentiation Strategies</h2>
            <h3>Supporting Struggling Learners</h3>
            <ul>
                <li>Provide a partially completed diagram for Task 2</li>
                <li>Create vocabulary cards with simplified definitions and visual cues</li>
                <li>Offer sentence starters for written responses</li>
                <li>Use a guided reading approach with frequent comprehension checks</li>
                <li>Pair students for peer support during task completion</li>
            </ul>
            
            <h3>Challenging Advanced Learners</h3>
            <ul>
                <li>Ask students to research and include the specific biochemical pathways (Krebs cycle, electron transport chain)</li>
                <li>Have students calculate exact ATP yields and energy efficiency percentages</li>
                <li>Challenge students to connect cellular respiration to current environmental issues</li>
                <li>Assign research on specialized anaerobic organisms in extreme environments</li>
                <li>Encourage students to design their own experimental protocols to test respiration concepts</li>
            </ul>
        </div>
        
        <div class="resources">
            <h2 class="section-title">Additional Resources</h2>
            <ul>
                <li><strong>Videos:</strong> Crash Course Biology: Cellular Respiration and Fermentation</li>
                <li><strong>Simulations:</strong> PhET Interactive Simulations on Cellular Respiration</li>
                <li><strong>Articles:</strong> Scientific American articles on carbon cycling and cellular respiration</li>
                <li><strong>Websites:</strong> HHMI BioInteractive resources on cellular processes</li>
                <li><strong>Books:</strong> "The Chemistry of Life" by Steven Rose for advanced reading</li>
            </ul>
        </div>
    </div>
</body>
</html>`;

